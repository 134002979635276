import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import { useNavigate, useParams } from 'react-router-dom';
import FetchData from '../../api/FetchData';
import Loader from '../../components/loader/Loader';
import { Button, CircularProgress, InputLabel, NativeSelect, Select, TextField, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { lng } from '../../utils/language';
import style from './Overall.module.css';
import Countries from './Countries.json';
// import test from './test.json';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import EmailAlert from '../../components/functions/EmailAlert';
import { deleteUser, getDownPaymentType, getFinancingType, getUserData, removeDownPayment, saveUserData } from '../../utils/utils';
// import NativeSelect from '@mui/material/NativeSelect';

const defaultAlert = {
    open: false,
    severity: '',
    msg: ''
}

const defaultForm = {
    firstname: getUserData()?.firstname ? getUserData().firstname : '',
    lastname: getUserData()?.lastname ? getUserData().lastname : '',
    email: getUserData()?.email ? getUserData().email : '',
    tel: getUserData()?.tel ? getUserData().tel : '',
    prefix: getUserData()?.prefix ? getUserData().prefix : '',
    otherCounty: getUserData()?.otherCounty ? getUserData().otherCounty : '',
    address: getUserData()?.address ? getUserData().address : '',
    
    countryId: getUserData()?.countryId ? getUserData().countryId : '',
    cityName: getUserData()?.cityName ? getUserData().cityName : '',
    countryName: getUserData()?.countryName ? getUserData().countryName : '',
    cityId: getUserData()?.cityId ? getUserData().cityId : '',

    postalCode: getUserData()?.postalCode ? getUserData().postalCode : '',
    userId: getUserData()?.userId ? getUserData().userId : '',
    // termsAndConditions: false
}

const defaultErrorForm = {
    firstnameError: false,
    lastnameError: false,
    emailError: false,
    telError: false,
    prefixError: false,
    otherCountyError: false,
    countryError: false,
    addressError: false,
    cityError: false,
    postalCodeError: false,
    userIdError: false,
}

const info = {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '10px',
    borderBottom: 'solid 1px #000',
    justifyContent: 'space-between',
}

const inputStyle = {
    '.MuiInputBase-root': {
        borderRadius: 0,
    }
}

export default function RezidenciesOffert() {

    const params = useParams();
    const language = useSelector((state) => state.language.language);
    const [apartament, setApartament] = useState();
    // const [fullPrice, setFullPrice] = useState(null);
    const [loading, setLoading] = useState(true);
    const [alert, setAlert] = useState(defaultAlert)
    const [loadingButton, setLoadingButton] = useState(false);
    const [formData, setFormData] = useState(defaultForm)
    const [formDataErrors, setFormDataErrors] = useState(defaultErrorForm)

    const [cities, setCities] = useState([]);

    const inputChecks = {
        firstnameInput: (e) => { var value = e.target.value; if (value !== "" && !value.match(/^[A-Za-z]( ?[A-Za-z] ?)*$/g)) { setFormDataErrors({ ...formDataErrors, firstnameError: true }) } else if (value.match(/^[A-Za-z]( ?[A-Za-z] ?)*$/g) || value !== "") { setFormDataErrors({ ...formDataErrors, firstnameError: false }) } setFormData({ ...formData, firstname: value }) },
        lastnameInput: (e) => { var value = e.target.value; if (value !== "" && !value.match(/^[A-Za-z]( ?[A-Za-z] ?)*$/g)) { setFormDataErrors({ ...formDataErrors, lastnameError: true }) } else if (value.match(/^[A-Za-z]( ?[A-Za-z] ?)*$/g) || value !== "") { setFormDataErrors({ ...formDataErrors, lastnameError: false }) } setFormData({ ...formData, lastname: value }) },
        emailInput: (e) => { var value = e.target.value; if (value !== "" && !value.match(/^[\w\-.]+@([\w-]+\.)+[\w-]{2,}/)) { setFormDataErrors({ ...formDataErrors, emailError: true }) } else if (value.match(/^[\w\-.]+@([\w-]+\.)+[\w-]{2,}/) || value === "") { setFormDataErrors({ ...formDataErrors, emailError: false }) } setFormData({ ...formData, email: value }) },
        prefixInput: (e) => { var value = e.target.value; if (value !== "") { setFormDataErrors({ ...formDataErrors, prefixError: false }) } setFormData({ ...formData, prefix: value }); },
        telInput: (e) => { var value = e.target.value; const regex = /^[0-9\s]*$/; if (value !== "" && !value.match(regex)) { setFormDataErrors({ ...formDataErrors, telError: true }) } else if (value.match(regex) || value !== "") { setFormDataErrors({ ...formDataErrors, telError: false }) } setFormData({ ...formData, tel: value }) },
        otherCountryInput: (e) => {var value = e.target.value; if (value !== "") { setFormDataErrors({ ...formDataErrors, otherCountyError: false }) } setFormData({ ...formData, otherCounty: value });},
        addressInput: (e) => { var value = e.target.value; if (value !== "") { setFormDataErrors({ ...formDataErrors, addressError: false }) } setFormData({ ...formData, address: value }); },
        
        countryInput: (e) => {
            var targetOption = e.target.options[e.target.selectedIndex];
            var value = e?.target?.value; 
            var countryname = targetOption.getAttribute('data-countryname');
            filterCities(countryname)
            if (value !== "") {
                setFormDataErrors({ 
                    ...formDataErrors, 
                    countryError: false 
                }) 
            };
            setFormData({ 
                ...formData, 
                countryId: value,
                countryName: countryname,
                cityName: ''
            });
        },
        
        cityInput: (e) => { 
            var targetOption = e.target.options[e.target.selectedIndex];
            var value = e?.target?.value; 
            var cityId = targetOption.getAttribute('data-cityId');

            if (value !== "") { 
                setFormDataErrors({ 
                    ...formDataErrors,
                    cityError: false
                }) 
            };
            setFormData({ 
                ...formData, 
                cityId: cityId,
                cityName: value
            }); 
        },
        
        postalCodeInput: (e) => { var value = e.target.value; const regex = /^[0-9\s]*$/; if (value !== "" && !value.match(regex)) { setFormDataErrors({ ...formDataErrors, postalCodeError: true }) } else if (value.match(regex) || value !== "") { setFormDataErrors({ ...formDataErrors, postalCodeError: false }) } setFormData({ ...formData, postalCode: value }) },
        userIdCodeInput: (e) => { 
            var value = e.target.value; 
            if (value !== "") { 
                setFormDataErrors({ ...formDataErrors, userIdError: false }) 
            } 
            setFormData({ ...formData, userId: value });
        },
    }

    const valiedateData = () => {
        setFormDataErrors(prevErrors => ({
            ...prevErrors,
            firstnameError: formData.firstname === '',
            lastnameError: formData.lastname === '',
            emailError: formData.email === '',
            telError: formData.tel === '',
            prefixError: formData.prefix === '',
            otherCountyError: formData.otherCounty === '',
            countryError: formData.countryName === '' || formData.countryName === null ,
            addressError: formData.address === '',
            cityError: formData.cityName === '' || formData.cityName === null,
            postalCodeError: formData.postalCode === '',
            userIdError: formData.userId === '',
            // Add similar checks for other fields if needed
        }));

        const errors = {
            firstnameError: formData.firstname === '',
            lastnameError: formData.lastname === '',
            emailError: formData.email === '',
            telError: formData.tel === '',
            prefixError: formData.prefix === '',
            countryError: formData.countryName === '' || formData.countryName === null,
            addressError: formData.address === '',
            cityError: formData.cityName === '' || formData.cityName === null,
            postalCodeError: formData.postalCode === '',
            userIdError: formData.userId === '',
        }
        return errors;
    }

    // const [type, setType] = useState(getFinancingType());
    const navigate = useNavigate();
    const handleSubmit = (e) => {
        e.preventDefault();
        const validation = Object.values(valiedateData());
        const confirmation = validation.includes(true);
        if (!confirmation) {
            setLoadingButton(true)
            FetchData.create('/email/confirmUser', {
                userData: formData,
                lang: language,
                typeOf: getFinancingType(),
                aptId: params.id,
                fileLocation: `../documents/${apartament.pdfFileName}`,
                downPayment: getDownPaymentType(),
                building: `${language === 'eng' ? apartament.apartmentName.replace('Apartamenti', 'Property') : apartament.apartmentName} ${language === 'eng' ? apartament.buildingName.replace('Godina', 'Building') : apartament.buildingName} ${apartament.objectName}`
            })
                .then(res => {
                    if (res?.data?.success) {
                        setAlert({
                            open: true,
                            severity: res?.data?.severity,
                            msg: res?.data?.msg
                        });
                        setFormData(defaultForm);
                        if (res?.data?.userAlreadyLogged) {
                            deleteUser();
                        } 
                        else if(res?.data?.notConfirmed){
                            handleRidirect(res.data.useToken);
                        }
                        //eslint-disable-next-line
                        else if(res?.data?.userAlreadyLogged == false || res?.data?.notConfirmed){
                            saveUserData(
                                {
                                    firstname: formData.firstname,
                                    lastname: formData.lastname,
                                    email: formData.email,
                                    tel: formData.tel,
                                    prefix: formData.prefix,
                                    otherCounty: formData.otherCounty,
                                    address: formData.address,
                                    countryName: formData.countryName,
                                    cityName: formData.cityName,
                                    countryId: formData.countryId,
                                    cityId: formData.cityId,
                                    postalCode: formData.postalCode,
                                    // userId: formData.userId,
                                }
                            )
                            handleRidirect(res.data.useToken);
                        }
                    }
                    setLoadingButton(false)
                })
                .catch(err => {
                })
        } else setLoadingButton(false);
    }
    
    const handleRidirect = (data) => {
        setTimeout(() => {
            navigate(`../userData/?id=${data}&reserveId=${params.id}`);
        }, [3000])
    }

    const obtainData = () => {
        if (getFinancingType() !== "getOfferWithBank") {
            removeDownPayment();
        }
        FetchData.create('/apartments/getSpecificApartemnt', { apartmentId: params.id })
            .then(res => {
                setApartament(res?.data?.data[0]);
                setLoading(false);
            })
            .catch(err => {
            })
    }

    function dude() {
        let arr = [];
        // eslint-disable-next-line
        Countries.map((data) => {
            arr.push({
                flag: JSON.stringify(data.flag),
                iso: data.cca2,
                name: data.name
            })
        })
    }

    const filterCities = (countryName) => {
        const selectedCountryData = Countries.find(
        // eslint-disable-next-line
        (country) => country.country === countryName);
        if (selectedCountryData) {
        setCities(selectedCountryData.cities);
        } else {
        setCities([]);
        }
    }
    
    useEffect(() => {
        dude();
        obtainData();
        window.scrollTo(0, 0);
        if (getUserData()?.countryName) {
            filterCities(getUserData()?.countryName);
        }
        // setType(getFinancingType())
        // eslint-disable-next-line
    }, [params.id])

    return (
        <Box>
            {
                loading === false ?
                    apartament &&
                    <Box sx={{ width: { md: '1024px', xs: '90%' }, marginX: 'auto', color: '#002856' }}>
                        <Box>
                        {
                                apartament.typology === 'DUPLEX' ?
                                    <Typography variant="h3" sx={{ fontWeight: 'light' }} textAlign={'center'} marginTop={5} marginBottom={2}>
                                        {
                                            apartament.apartmentId === '1650'
                                                ?
                                                language === 'alb' ? 'DUPLEX 1 Building 1 B2'.replace('Building', 'Godina') : 'DUPLEX 1 Building 1 B2'
                                                :
                                                ''
                                        }
                                        {
                                            apartament.apartmentId === '1651'
                                                ?
                                                language === 'alb' ? 'DUPLEX 2 Building 2 B2'.replace('Building', 'Godina') : 'DUPLEX 2 Building 2 B2'
                                                :
                                                ''
                                        }
                                        {
                                            apartament.apartmentId === '1652'
                                                ?
                                                language === 'alb' ? 'DUPLEX 3 Building 3 B2'.replace('Building', 'Godina') : 'DUPLEX 3 Building 3 B2'
                                                :
                                                ''
                                        }
                                    </Typography>
                                    :
                                    <Typography variant="h3" sx={{ fontSize: '30px', fontWeight: 'light' }} textAlign={'center'} marginTop={5} marginBottom={2}>{language === 'eng' ? apartament.apartmentName.replace('Apartamenti', 'Property') : apartament.apartmentName} {language === 'eng' ? apartament.buildingName.replace('Godina', 'Building') : apartament.buildingName} {apartament.objectName}</Typography>
                            }
                        </Box>
                        <Box sx={{ width: { md: '1024px', sm: '100%', xs: '100%' }, rowGap: '10px', marginX: 'auto', display: 'grid', gridTemplateRows: 'auto auto auto auto auto auto' }}>
                            <Box sx={info}>
                                <Box display={'flex'} alignItems={'center'} className="containerLogoAndText">
                                    <Box component={'img'} src='https://fundway.com/images/2024/stair.svg' sx={{ width: { sm: '35px', xs: '30px' }, height: '100%', objectFit: 'contain' }} />
                                    <Typography marginLeft={'10px'} fontWeight={500} sx={{ fontSize: { md: '1rem', xs: '14px' } }}>{lng[language]['flootTxt'].toUpperCase()}</Typography>
                                </Box>
                                <Typography fontWeight={500}>+{apartament.floorNo}</Typography>
                            </Box>

                            <Box sx={info}>
                                <Box display={'flex'} alignItems={'center'} className="containerLogoAndText">
                                    <Box component={'img'} src='https://fundway.com/images/2024/double-bed.svg' sx={{ width: { sm: '35px', xs: '30px' }, height: '100%', objectFit: 'contain' }} />
                                    <Typography marginLeft={'10px'} fontWeight={500} sx={{ fontSize: { md: '1rem', xs: '14px' } }}>{lng[language]['bedsTxt'].toUpperCase()}</Typography>
                                </Box>
                                <Typography fontWeight={500}>{apartament.typology}</Typography>
                            </Box>

                            <Box sx={info}>
                                <Box display={'flex'} alignItems={'center'} className="containerLogoAndText">
                                    <Box component={'img'} src='https://fundway.com/images/icons/eye.svg' sx={{ width: { sm: '35px', xs: '30px' }, height: '100%', objectFit: 'contain' }} />
                                    <Typography marginLeft={'10px'} fontWeight={500} sx={{ fontSize: { md: '1rem', xs: '14px' } }}>{lng[language]['orientation'].toUpperCase()}</Typography>
                                </Box>
                                <Typography fontWeight={500}>{lng[language][apartament.orientationName]}</Typography>
                            </Box>

                            {
                                apartament.surfaceTerrace &&
                                <Box sx={info}>
                                    <Box display={'flex'} alignItems={'center'} className="containerLogoAndText">
                                        <Box component={'img'} src='https://fundway.com/images/2024/terrace.svg' />
                                        <Typography>{lng[language]['terraceTxt'].toUpperCase()}</Typography>
                                    </Box>
                                    <Typography fontWeight={500}>{apartament.surfaceTerrace ? apartament.surfaceTerrace : '0'} M²</Typography>
                                </Box>
                            }



                            <Box sx={info}>
                                <Box display={'flex'} alignItems={'center'} className="containerLogoAndText">
                                    <Box component={'img'} src='https://fundway.com/images/2024/scalability.svg' sx={{ width: { sm: '35px', xs: '30px' }, height: '100%', objectFit: 'contain' }} />
                                    <Typography marginLeft={'10px'} fontWeight={500} sx={{ fontSize: { md: '1rem', xs: '14px' } }}>{lng[language]['apartmentSize'].toUpperCase()}</Typography>
                                </Box>
                                <Typography fontWeight={500}>{apartament.surfaceGrossWithoutTerrace} M²</Typography>
                            </Box>

                            <Box sx={info}>
                                <Box display={'flex'} alignItems={'center'} className="containerLogoAndText">
                                    <Box component={'img'} src='https://fundway.com/images/2024/scalability.svg' sx={{ width: { sm: '35px', xs: '30px' }, height: '100%', objectFit: 'contain' }} />
                                    <Typography marginLeft={'10px'} fontWeight={500} sx={{ fontSize: { md: '1rem', xs: '14px' } }}>{lng[language]['areaTxt'].toUpperCase()}</Typography>
                                </Box>
                                <Typography fontWeight={500}>{apartament.surfaceGrossWithTerrace} M²</Typography>
                            </Box>

                            {
                                apartament.status === 'free'
                                    ?
                                    <>
                                        <Box sx={info}>
                                            <Box display={'flex'} alignItems={'center'}>
                                                <Box component={'img'} src='https://fundway.com/images/2024/parking.svg' sx={{ width: { sm: '24px', xs: '24px !important' }, height: '100%', objectFit: 'contain' }} />
                                                <Typography marginLeft={'10px'} fontWeight={500} sx={{ fontSize: { md: '1rem', xs: '14px' } }}>PARKING</Typography>
                                            </Box>
                                            {/* <Typography fontWeight={500}>EUR {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', maximumSignificantDigits: 10 }).format(apartament.priceParking)}</Typography> */}
                                            <Typography fontWeight={500}>
                                                        {lng[language]['parking1']}
                                                    </Typography>
                                        </Box>
                                        {/* <Box sx={info}>
                                            <Box display={'flex'} alignItems={'center'} className="containerLogoAndText">
                                                <Box component={'img'} src='https://fundway.com/images/2024/money.svg' sx={{ width: { sm: '35px', xs: '30px' }, height: '100%', objectFit: 'contain' }} />
                                                <Typography marginLeft={'10px'} fontWeight={500} sx={{ fontSize: { md: '1rem', xs: '14px' } }}>{lng[language]['priceTxt'].toUpperCase()}</Typography>
                                            </Box>
                                            <Typography fontWeight={500}>EUR {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', maximumSignificantDigits: 10 }).format(Math.ceil((parseFloat(apartament.priceTotal) + parseInt(apartament.priceParking)) / 100) * 100)}</Typography>
                                        </Box> */}
                                    </>
                                    :
                                    ''
                            }
                        </Box>
                        {/* DETAILS */}
                        <Box sx={{ marginTop: '50px' }}>
                            <Typography variant="h4" fontSize={21} fontWeight={'bold'}>{lng[language]['rezerveDetailFilterTitle']}</Typography>

                            <Box sx={{ display: 'grid', gridTemplateRows: { md: 'unset', xs: 'auto' }, gridTemplateColumns: { md: '1fr 1fr', xs: 'unset' }, rowGap: '20px', columnGap: { md: '20px', xs: 'unset' }, width: '100%', marginTop: '25px' }}>
                                {/* NAME */}
                                <Box className={style.reserveApsInputs}>
                                    <InputLabel>{lng[language]['rezerveDetailFilterName']}</InputLabel>
                                    <TextField
                                        sx={inputStyle}
                                        fullWidth
                                        value={formData.firstname}
                                        error={formDataErrors.firstnameError}
                                        onChange={(e) => inputChecks.firstnameInput(e)}
                                    />
                                </Box>
                                {/* LAST NAME */}
                                <Box className={style.reserveApsInputs}>
                                    <InputLabel>{lng[language]['rezerveDetailFilterLastname']}</InputLabel>
                                    <TextField
                                        sx={inputStyle}
                                        fullWidth
                                        value={formData.lastname}
                                        error={formDataErrors.lastnameError}
                                        onChange={(e) => inputChecks.lastnameInput(e)}
                                    />
                                </Box>
                                {/* EMAIL */}
                                <Box className={style.reserveApsInputs}>
                                    <InputLabel>{lng[language]['rezerveDetailFilterEmail']}</InputLabel>
                                    <TextField
                                        sx={inputStyle}
                                        fullWidth
                                        value={formData.email}
                                        error={formDataErrors.emailError}
                                        onChange={(e) => inputChecks.emailInput(e)}
                                    />
                                </Box>
                                {/* MOBILE */}
                                <Box className={style.reserveApsInputs}>
                                    <InputLabel>{lng[language]['rezerveDetailFilterMob']}</InputLabel>
                                    <Box sx={{ display: 'grid', gridTemplateColumns: '120px 1fr', columnGap: '20px' }}>
                                        <FormControl sx={{ '.MuiInputBase-root': { borderRadius: 0 }, width: '120px' }}>
                                            <Select onChange={(e) => inputChecks.prefixInput(e)} error={formDataErrors.prefixError} value={formData.prefix}>
                                                {
                                                    Countries.map((data, index) => (
                                                        <MenuItem key={index} value={data.phone_code}>{data.phone_code} {data.flag}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                        <Box>
                                            <TextField
                                                type='tel'
                                                value={formData.tel}
                                                fullWidth
                                                sx={inputStyle}
                                                error={formDataErrors.telError}
                                                onChange={(e) => inputChecks.telInput(e)}
                                            />
                                        </Box>
                                    </Box>
                                    
                                </Box>
                                {/* COUNTRY OF ORIGIN ? */}
                                <Box className={style.reserveApsInputs}>
                                    <InputLabel>{lng[language]['rezerveDetailFilterCountry']}</InputLabel>
                                     <FormControl sx={{ '.MuiInputBase-root': { borderRadius: 0 }, width: '100%' }}>
                                     <Select onChange={(e) => inputChecks.otherCountryInput(e, )} error={formDataErrors.otherCountyError} value={formData.otherCounty}>
                                            {
                                                Countries.map((data, index) => (
                                                    <MenuItem key={index} countryname={data.country} value={Number(data.countryId)}>{data.country} {data.flag}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </Box>
                                {/* COUNTRY WHERE USER LIVES */}
                                <Box className={style.reserveApsInputs}>
                                    <InputLabel>{lng[language]['rezerveDetailFilterCountryOfResidece']}</InputLabel>
                                     <FormControl sx={[{ '.MuiInputBase-root': { borderRadius: 0, '::before': {content: 'none'}, '::after': {content: 'none'}, border: 'solid 1px', borderColor: formDataErrors.countryError ? '#d32f2f !important' : '#c4c4c4', '&:hover': {borderColor: '#000'} },'.MuiNativeSelect-select': {padding: '15px !important'}, width: '100%' }]}>
                                        <NativeSelect onChange={(e) => inputChecks.countryInput(e)} value={formData.countryId}>
                                            <option value="">{lng[language]['selectCountry']}</option>
                                            {
                                                Countries.map((data, index) => (
                                                    <option key={index} data-countryname={data.country} value={Number(data.countryId)}>{data.country} {data.flag}</option>
                                                ))
                                            }
                                        </NativeSelect>
                                    </FormControl>
                                </Box>
                                {/* ADDRESS */}
                                <Box className={style.reserveApsInputs}>
                                    <InputLabel>{lng[language]['rezerveDetailFilterAddress']}</InputLabel>
                                    <TextField
                                        sx={inputStyle}
                                        fullWidth
                                        value={formData.address}
                                        error={formDataErrors.addressError}
                                        onChange={(e) => inputChecks.addressInput(e)}
                                    />
                                </Box>
                                {
                                    console.log(cities)
                                }
                                {/* CITY */}
                                <Box className={style.reserveApsInputs}>
                                    <InputLabel>{lng[language]['rezerveDetailFilterCity']}</InputLabel>
                                    <FormControl sx={[{ '.MuiInputBase-root': { borderRadius: 0, '::before': {content: 'none'}, '::after': {content: 'none'}, border: 'solid 1px', borderColor: formDataErrors.cityError ? '#d32f2f !important' : '#c4c4c4', '&:hover': {borderColor: '#000'} },'.MuiNativeSelect-select': {padding: '15px !important'}, width: '100%' }]} disabled={formData.countryName ? false : true}>
                                        <NativeSelect onChange={(e) => inputChecks.cityInput(e)} error={formDataErrors.cityError} value={formData.cityName}>
                                            <option value="">{lng[language]['selectCity']}</option>
                                            {
                                                cities.map((data, index) => (
                                                    <option data-cityId={data?.id} key={index} value={data.city}>{data.city}</option>
                                                ))
                                            }
                                        </NativeSelect>
                                    </FormControl>
                                </Box>
                                {/* POSTAL CODE */}
                                <Box className={style.reserveApsInputs}>
                                    <InputLabel>{lng[language]['rezerveDetailFilterPostalCode']}</InputLabel>
                                    <TextField
                                        type='tel'
                                        sx={inputStyle}
                                        fullWidth
                                        value={formData.postalCode}
                                        error={formDataErrors.postalCodeError}
                                        onChange={(e) => inputChecks.postalCodeInput(e)}
                                    />
                                </Box>
                                {/* ID */}
                                <Box className={style.reserveApsInputs}>
                                    <InputLabel>{lng[language]['rezerveDetailFilterNumberId'].toUpperCase()}</InputLabel>
                                    <TextField
                                        type='text'
                                        sx={inputStyle}
                                        fullWidth
                                        value={formData.userId}
                                        error={formDataErrors.userIdError}
                                        onChange={(e) => inputChecks.userIdCodeInput(e)}
                                    />
                                </Box>
                            </Box>

                            <Box sx={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
                                <Button disabled={loadingButton ? true : false} onClick={(e) => !loadingButton ? handleSubmit(e) : null} sx={{ width: !loadingButton ? '200px' : '200px', height: '50px', backgroundColor:  loadingButton ? '#002856 !important': '#002856' , color: loadingButton ? '#FFF !important' : '#FFF', border: 'solid 1px #002856', borderRadius: 0, '&:hover': { backgroundColor: '#FFF', color: '#002856' } }}>
                                    {
                                        !loadingButton ?
                                        lng[language]['takeOffer']
                                        :
                                        <CircularProgress sx={{width: '25px !important', height: '25px !important', color: '#FFF'}} />
                                    }
                                </Button>
                            </Box>
                            {
                                alert.open &&
                                <EmailAlert open={alert.open} severity={alert.severity} setOpen={setAlert} msg={lng[language][alert.msg]} />
                            }
                        </Box>
                    </Box>
                    :
                    <Loader state={loading} />
            }
        </Box>
    )
}
