import Box from '@mui/material/Box';
import AllRoutes from './routes/index';
import Header from './layout/header/Header';
import Footer from './layout/footer/Footer';
import { useLocation } from 'react-router-dom';
import env from "react-dotenv";
import ReactPixel from './utils/reactPixel';
// import ReactGA from "react-ga4";
import { useEffect } from 'react';
// import { useDispatch } from 'react-redux';
// import { useEffect } from 'react';
// import { initiateTempToken, isTokenValid } from './api/utils';
// import { validateUser } from './app/auth/Auth';

function App() {
    const location = useLocation();
  
    useEffect(() => {
        console.log(env.REACT_APP_PIXEL_KEY);
        ReactPixel.init(env.REACT_APP_PIXEL_KEY);
        // ReactGA.initialize("G-5EG2GQ5QD1");
        // ReactGA.send({ hitType: "PageViews", page: window.location.pathname });
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
        ReactPixel.pageView();
        // ReactGA.send({ hitType: "PageViews", page: window.location.pathname });
    }, [location]);

    return (
        <Box sx={{backgroundColor:'#FFF'}}>
            <Header />
            {/* <Box sx={{minHeight: {md: 'calc(100vh - 104px)'}}}> */}
                <AllRoutes />
            {/* </Box> */}
            <Footer />
        </Box>
    );
}

export default App;