export function insertItems (data, remove) {
    if (remove) {
        let items =JSON.parse(localStorage.getItem("compare"));
        items = items.filter((item) => item !== data);
        localStorage.setItem("compare", JSON.stringify(items));
        if (items.length === 0) {
          localStorage.removeItem("compare");
        }
    }else{
        localStorage.setItem('compare', JSON.stringify(data));
    }
}

export function checkIfExist() {
    if (localStorage.getItem("compare")) {
        let items = JSON.parse(localStorage.getItem("compare"));
        if (items !== null || items?.length !== 0) {
            return {
                data: items,
                success: true
            }
        }else{
            return {
                data: [],
                success: false
            }
        }    
    }else{
        return {
            data: [],
            success: false
        }
    }
}

export function resetComp(){
    localStorage.removeItem("compare");
}

export function registerItems(data){
    if (data) {
        if (localStorage.getItem("compare")) {
            let items = JSON.parse(localStorage.getItem("compare"));
           if (items) {
                localStorage.removeItem("compare");
                if (data === Array) {
                    localStorage.setItem("compare", JSON.stringify(data));
                }else{
                    const newData = data.split(',')
                    localStorage.setItem("compare", JSON.stringify(newData));
                }
           }
        }else{
            if (data === Array) {
                localStorage.setItem("compare", JSON.stringify(data));
            }else{
                const newData = data.split(',')
                localStorage.setItem("compare", JSON.stringify(newData));
            }
        }
    }else{
        return
    }
   
}

export function selectedItem(data){
    localStorage.removeItem("specialSelect");
    if (data) {
        localStorage.setItem("specialSelect", JSON.stringify(data));
    }else{
        return
    }
}

export function getSelectedItem(){
    let items = JSON.parse(localStorage.getItem("specialSelect"));
    return items;
}

// DOWNPAYMENT START
export function insertDownPayment(data) {
    localStorage.removeItem("downpayment");
    if (data) {
        localStorage.setItem("downpayment", JSON.stringify(data));
    }else{
        return
    }
} 

export function getDownPaymentType(){
    let items = JSON.parse(localStorage.getItem("downpayment"));
    return items;
}

export function removeDownPayment() {
    localStorage.removeItem("downpayment");
}

// FINANC TYPE
export function financingType(data){
    localStorage.removeItem("financType");
    if (data) {
        localStorage.setItem("financType", JSON.stringify(data));
        if (data === "getOfferWithBank") {
            localStorage.removeItem("downpayment");
            localStorage.setItem("downpayment", JSON.stringify(20));
        }
    }else{
        return
    }
}

export function getFinancingType(){
    let items = JSON.parse(localStorage.getItem("financType"));
    return items;
}

export function saveUserData(data) {
    localStorage.removeItem("userData");
    if (data) {
        localStorage.setItem("userData", JSON.stringify(data));
    }else{
        return
    }
}

export function deleteUser() {
    // localStorage.removeItem("userData");
}

export function getUserData() {
    let items = JSON.parse(localStorage.getItem("userData"));
    if (items) {
        return items;
    }else{
        return null;
    }
}