// CONFIGURE FOR REDUX-TOOLKIT
import { configureStore } from '@reduxjs/toolkit';

// REDUCERS
import authReducer  from './auth/Auth';
import languageReducer  from './language/Language';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        language: languageReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
})